import { booleanAttribute, ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Primitive } from '../../../models/Primitive';
import { IrisFieldBaseComponent } from '../field-base/field-base.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ButtonGroupSelectorOptionI {
  value: Primitive;
  title: string;
  icon?: string;
  tooltip?: string;
}

export enum ButtonGroupView {
  Default = 'default',
  Tab = 'tab',
}

const itemActivityClassScheme = {
  [ButtonGroupView.Default]: {
    selected: 'btn-success',
    unselected: 'btn-default',
  },
  [ButtonGroupView.Tab]: {
    selected: 'selected',
    unselected: '',
  },
};

@Component({
  selector: 'iris-field-button-group-selector',
  templateUrl: './button-group-selector.component.html',
  styleUrls: ['./button-group-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisFieldButtonGroupSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
})
export class IrisFieldButtonGroupSelectorComponent
  extends IrisFieldBaseComponent implements ControlValueAccessor {

  @Input() options: ButtonGroupSelectorOptionI[] = [];
  @Input() view: ButtonGroupView = ButtonGroupView.Default;
  @Input() multi = false;
  @Input({ transform: booleanAttribute }) resetOnSecondClick: boolean = false;

  public disabled = false;

  constructor() {
    super([]);
  }

  isSelected(item: ButtonGroupSelectorOptionI): boolean {
    if (this.multi) {
      return Array.isArray(this.valueControl.value) && this.valueControl.value.includes(item.value);
    }
    return this.valueControl.value === item.value;
  }

  onSelect(value: Primitive): void {
    if (this.disabled) {
      return;
    }

    if (this.multi) {
      let newVal: Primitive[] = [value];
      if (Array.isArray(this.valueControl.value)) {
        newVal = this.valueControl.value.includes(value) ?
          this.valueControl.value.filter(item => item !== value) :
          [...this.valueControl.value ?? [], value];

      }

      this.valueControl.setValue(newVal);
    } else {
      if (this.resetOnSecondClick !== false && value === this.valueControl.value) {
        this.valueControl.reset(null);
      } else {
        this.valueControl.setValue(value);
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getOptionClass(item: ButtonGroupSelectorOptionI): string {
    return itemActivityClassScheme[this.view][this.isSelected(item) ? 'selected' : 'unselected'];
  }
}
