import { patchState, signalStore, withComputed, withMethods } from '@ngrx/signals';
import { removeEntities, removeEntity, setAllEntities, setEntities, setEntity, updateEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { IrisEmailMessage, IrisEmailMessageI, IrisEmailMessageResponseI } from '../../models/IrisEmail';
import { setLoaded, setLoading, withRequestState } from '@iris/common/signals/features/request-status.feature';
import { computed, EventEmitter, inject } from '@angular/core';
import { IrisEmailsService } from '../../services/emails.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, forkJoin, map, mergeMap, pipe, switchMap, take, takeUntil, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ForwardOrReplyMessageSuccess,
  MarkMessageAsReadSuccess,
  MarkMessageAsUnreadSuccess,
  MoveMessagesToFolderSuccess,
  RemoveEmailMessageSuccess,
  RemoveMessageAttachmentSuccess,
  RemoveSelectedEmailMessagesSuccess,
  UploadAttachmentsFromDmsSuccess,
  UploadMessageAttachmentsSuccess,
} from './emails-messages.actions';
import { IrisEmailsBulkActionParamsI } from './emails-messages.sandbox';
import { ResetMessagesSelection } from '../global/emails-global.actions';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { difference, isNil } from 'lodash';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';

export const IrisEmailsMessagesStore = signalStore(
  { providedIn: 'root' },
  withEntities<IrisEmailMessageI>(),
  withRequestState(),
  withComputed(store => ({
    messagesFlatList: computed(() => store.entities().flatMap((message) => [message, ...(message.relatedMessages || [])])),
  })),
  withMethods(store => ({
    getMessageById: (messageId: string) => computed(() => store.entityMap()[messageId]),
    loadingStart: () => patchState(store, setLoading()),
    loadingEnd: () => patchState(store, setLoaded()),
    deleteMessage: (messageId: string) => patchState(store, removeEntity(messageId)),
    addMessages: (params: { messages: IrisEmailMessageResponseI[]; replace: boolean; offset?: number; limit?: number }) => {
      const { offset, limit, messages, replace } = params;
      const mappedMessages = (messages ?? []).map(message => new IrisEmailMessage().fromResponse(message));
      if (replace) {
        patchState(store, setAllEntities(mappedMessages));
        return;
      }
      if (!isNil(offset) && !isNil(limit)) {
        const existingMessages = store.entities();
        existingMessages.splice(offset, limit, ...mappedMessages);
        patchState(store, setAllEntities(existingMessages));
        return;
      }
      patchState(store, setEntities(mappedMessages));
    },
  })),
  withMethods((
    store,
    emailsService = inject(IrisEmailsService),
    alertify = inject(IrisAlertService),
    translateService = inject(TranslateService),
    rxStore = inject(Store),
  ) => ({
    fetchMessage$: rxMethod<string>(
      pipe(
        mergeMap(messageId => emailsService.getMessageById(messageId).pipe(
          map(message => {
            const existMessage = store.getMessageById(messageId)();
            const attachments = message.hasAttachments ? existMessage?.attachments ?? message?.attachments : [];
            const messageToSet = new IrisEmailMessage({ ...message, attachments });
            patchState(store, setEntity(messageToSet));
          }),
        )),
      ),
    ),
    markMessageAsRead$: rxMethod<string>(
      pipe(
        mergeMap(messageId => emailsService.markMessageAsRead(messageId).pipe(
          map(message => patchState(store, updateEntity({ id: messageId, changes: { markedAsRead: message.markedAsRead } }))),
          tap(() => rxStore.dispatch(MarkMessageAsReadSuccess())),
        )),
      ),
    ),
    markMessageAsUnread$: rxMethod<string>(
      pipe(
        mergeMap(messageId => emailsService.markMessageAsUnread(messageId).pipe(
          map(message => patchState(store, updateEntity({ id: messageId, changes: { markedAsRead: message.markedAsRead } }))),
          tap(() => rxStore.dispatch(MarkMessageAsUnreadSuccess())),
        )),
      ),
    ),
    setMessageReadMarkBulk$: rxMethod<{ read: boolean; params: IrisEmailsBulkActionParamsI }>(
      pipe(
        switchMap(({ read, params }) => emailsService.setMessagesReadMarkBulk(
          params.selectedFolderId,
          read,
          params.selectedMessagesIds,
          params.unselectedMessagesIds,
        ).pipe(
          map(() => {
            const messagesIds = store.ids();
            const messagesToUpdate = messagesIds
              .filter((messageId: string) => {
                return params.unselectedMessagesIds.length
                  ? !params.unselectedMessagesIds.includes(messageId)
                  : !params.selectedMessagesIds.length || params.selectedMessagesIds.includes(messageId);
              });
            patchState(store, updateEntities({ ids: messagesToUpdate, changes: { markedAsRead: read } }));
            rxStore.dispatch(ResetMessagesSelection());
          }),
        )),
      ),
    ),
    removeMessage$: rxMethod<string>(
      pipe(
        map((messageId) => {
          const message = store.getMessageById(messageId)();
          store.deleteMessage(messageId);
          return message;
        }),
        filter(Boolean),
        switchMap(message => {
          return emailsService.removeMessage(message.id).pipe(
            tap(() => rxStore.dispatch(RemoveEmailMessageSuccess({ message }))),
          );
        }),
      ),
    ),
    removeSelectedMessages$: rxMethod<IrisEmailsBulkActionParamsI>(
      pipe(
        switchMap(({ selectedMessagesCount, selectedMessagesIds, unselectedMessagesIds, selectedFolderId, deletedEmailsSelected }) => {
          const confirmMessage = deletedEmailsSelected ? 'label.email.PermanentlyDeleteEmailsConfirmation' : 'label.email.DeleteEmailsConfirmation';
          return alertify.confirm$({
            message: translateService.instant(confirmMessage, { count: selectedMessagesCount }),
          }).pipe(
            filter(Boolean),
            tap(() => {
              if (selectedMessagesIds.length) {
                return patchState(store, removeEntities(selectedMessagesIds));
              }
          
              const allMessagesIds = store.ids();
              const messagesToRemove = difference(allMessagesIds, unselectedMessagesIds);
              patchState(store, removeEntities(messagesToRemove));
            }),
            switchMap(() => emailsService.removeMessagesBulk(selectedFolderId, selectedMessagesIds, unselectedMessagesIds).pipe(
              tap(() => {
                rxStore.dispatch(
                  RemoveSelectedEmailMessagesSuccess({ selectedMessagesIds, unselectedMessagesIds, selectedFolderId, selectedMessagesCount }),
                );
              }),
            )),
          );
        }),
      ),
    ),
    saveMessageToDms$: rxMethod<{ messageId: string; folderId: string }>(
      pipe(
        mergeMap(({ messageId, folderId }) => emailsService.saveMessageToDms(messageId, folderId).pipe(
          tap(() => {
            const message = store.getMessageById(messageId)();
            alertify.success(translateService.instant('text.email.MessageSavedToDms', { messageName: message.subject }));
          }),
        )),
      ),
    ),
    saveMessagesToDms$: rxMethod<{
      folderId: string;
      dmsFolderId: string;
      includedIds: string[];
      excludedIds: string[];
      dropped: boolean;
      selectedMessagesCount: number;
    }>(
      pipe(
        switchMap(params => emailsService.saveMessageToDmsBulk(params.folderId, params.dmsFolderId, params.includedIds, params.excludedIds).pipe(
          tap(() => {
            alertify.success(translateService.instant('text.email.MessagesSavedInFolder', {
              itemsCount: params.selectedMessagesCount,
            }));
          }),
        )),
      ),
    ),
    saveMessageAttachmentsToDms$: rxMethod<{ messageId: string; folderId: string }>(
      pipe(
        mergeMap(({ messageId, folderId }) => emailsService.saveMessagesAttachmentsToDms(messageId, folderId).pipe(
          tap(() => {
            const message = store.getMessageById(messageId)();
            alertify.success(translateService.instant('text.email.MessageAttachmentsSavedToDms', { messageName: message.subject }));
          }),
        )),
      ),
    ),
    moveMessagesToFolder$: rxMethod<{ messagesIds: string[]; targetFolderId: string; sourceFolderId: string }>(
      pipe(
        switchMap(({ messagesIds, targetFolderId, sourceFolderId }) => {
          patchState(store, removeEntities(messagesIds));
          return forkJoin(messagesIds.map(messageId => emailsService.moveMessageToFolder(messageId, targetFolderId).pipe(take(1))))
            .pipe(
              tap(() => rxStore.dispatch(MoveMessagesToFolderSuccess({ targetFolderId, sourceFolderId }))),
            );
        }),
      ),
    ),
    fetchMessageAttachments$: rxMethod<string>(
      pipe(
        switchMap(messageId => emailsService.getMessageAttachments(messageId).pipe(
          map(attachments => patchState(store, updateEntity({ id: messageId, changes: { attachments } }))),
        )),
      ),
    ),
    uploadMessagesAttachments$: rxMethod<{ messageId: string; files: File[] }>(
      pipe(
        switchMap(({ messageId, files }) => {
          return forkJoin(files.map(file => emailsService.uploadAttachment(messageId, file).pipe(take(1))))
            .pipe(
              tap(attachments => rxStore.dispatch(UploadMessageAttachmentsSuccess({ messageId, attachments }))),
            );
        }),
      ),
    ),
    uploadAttachmentsFromDms$: rxMethod<{ messageId: string; files: IrisDMSFileI[]; cancelEvent: EventEmitter<string> }>(
      pipe(
        switchMap(({ messageId, files, cancelEvent }) => {
          return forkJoin(files.map(file =>
            emailsService.uploadAttachmentFromDms(messageId, file.id).pipe(
              takeUntil(cancelEvent.pipe(
                filter(fileId => file.id === fileId),
              )),
            ),
          ))
            .pipe(
              tap(attachments => rxStore.dispatch(UploadAttachmentsFromDmsSuccess({ messageId, attachments }))),
            );
        }),
      ),
    ),
    removeMessagesAttachment$: rxMethod<{ messageId: string; attachmentId: string }>(
      pipe(
        mergeMap(({ messageId, attachmentId }) => emailsService.removeAttachment(messageId, attachmentId).pipe(
          map(() => rxStore.dispatch(RemoveMessageAttachmentSuccess({ messageId, attachmentId }))),
        )),
      ),
    ),
    saveMessageAttachmentToDms$: rxMethod<{ messageId: string; attachmentId: string; folderId: string }>(
      pipe(
        mergeMap(({ messageId, attachmentId, folderId }) =>
          emailsService.saveMessageAttachmentToDms(messageId, attachmentId, folderId).pipe(
            tap((file: IrisDMSFileI) => {
              alertify.success(translateService.instant('text.email.AttachmentSavedInDMS', { name: file.name }));
            }),
          ),
        ),
      ),
    ),
    forwardMessage$: rxMethod<{
      messageId: string;
      message: Partial<IrisEmailMessageResponseI>;
      silent: boolean;
    }>(
      pipe(
        switchMap(({ messageId, message, silent }) => emailsService.forwardMessage(messageId, message).pipe(
          tap(messageResponse => {
            const forwardedMessage = new IrisEmailMessage().fromResponse(messageResponse);
            rxStore.dispatch(ForwardOrReplyMessageSuccess({ message, silent, forwardedMessage }));
          }),
        )),
      ),
    ),
    replyToMessage$: rxMethod<{
      messageId: string;
      message: Partial<IrisEmailMessageResponseI>;
      silent: boolean;
    }>(
      pipe(
        switchMap(({ messageId, message, silent }) => emailsService.replyToMessage(messageId, message).pipe(
          tap(messageResponse => {
            const forwardedMessage = new IrisEmailMessage().fromResponse(messageResponse);
            rxStore.dispatch(ForwardOrReplyMessageSuccess({ message, silent, forwardedMessage }));
          }),
        )),
      ),
    ),
    replyToAll$: rxMethod<{
      messageId: string;
      message: Partial<IrisEmailMessageResponseI>;
      silent: boolean;
    }>(
      pipe(
        switchMap(({ messageId, message, silent }) => emailsService.replyToAll(messageId, message).pipe(
          tap(messageResponse => {
            const forwardedMessage = new IrisEmailMessage().fromResponse(messageResponse);
            rxStore.dispatch(ForwardOrReplyMessageSuccess({ message, silent, forwardedMessage }));
          }),
        )),
      ),
    ),
    fetchMessageConversation$: rxMethod<{ messageId: string; conversationId: string }>(
      pipe(
        switchMap(({ messageId, conversationId }) => emailsService.getMessageConversation(conversationId).pipe(
          tap(messages => {
            const relatedMessages = messages
              .filter(message => message.id !== messageId)
              .map(message => new IrisEmailMessage().fromResponse(message));
            patchState(store, updateEntity({ id: messageId, changes: { relatedMessages } }));
          }),
        )),
      ),
    ),
  })),
  withMethods(store => ({
    toggleMessageReadMark: (messageId: string) => {
      const message = store.getMessageById(messageId)();
      if (message.markedAsRead) {
        return store.markMessageAsUnread$(messageId);
      }
      return store.markMessageAsRead$(messageId);
    },
  })),
);