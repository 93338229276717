import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store, Action } from '@ngrx/store';
import { IrisEmailsService } from '../../services/emails.service';
import * as emailsReducer from '../index';
import {
  RemoveEmailMessageSuccess,
  RemoveSelectedEmailMessagesSuccess,
  MoveMessagesToFolderSuccess,
  FetchMessagesRangeStart,
  EmailsMessageChangeEvent,
  SetMessageReadMarkBulkSuccess,
  MarkMessageAsReadSuccess,
  MarkMessageAsUnreadSuccess,
  ForwardOrReplyMessageSuccess,
} from './emails-messages.actions';
import { switchMap, map, catchError, of, take, filter, withLatestFrom, forkJoin, bufferTime } from 'rxjs';
import { GetUnreadCountStart, ResetMessagesPagination, ResetMessagesSelection, SetOpenedDraftMessage, SetTotalItemsCount } from '../global/emails-global.actions';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailMessageChangeEventI, EmailMesssageChangeActionType } from '../../models/IrisEmail';
import { IrisEmailsRoutingService } from '@iris/modules/emails/services/emails-routing.service';
import { isEqual, isNil, isNumber, uniq, uniqWith } from 'lodash';
import { IrisEmailsNavigationStore } from '../navigation/emails-navigation.signal-store';
import { IrisEmailsMessagesStore } from './emails-messages.signal-store';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class IrisEmailsMessagesEffects {
  private readonly emailsNavigationStore = inject(IrisEmailsNavigationStore);
  private readonly emailsMessagesStore = inject(IrisEmailsMessagesStore);

  readonly updateSelectedFolderOnAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkMessageAsReadSuccess, MarkMessageAsUnreadSuccess, SetMessageReadMarkBulkSuccess),
      switchMap(() => this.store.pipe(select(emailsReducer.getSelectedMenuItemId), take(1))),
      switchMap((folderId) => {
        this.emailsNavigationStore.fetchCustomFolder$(folderId);
        return [];
      }),
    ),
  );

  readonly selectedMessageRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveEmailMessageSuccess),
      withLatestFrom(
        this.store.pipe(select(emailsReducer.getSelectedMessageId)),
      ),
      switchMap(([action, selectedMessageId]) => {
        const pagination = this.messagesPagination();
        const subject = action.message.subject ? `"${action.message.subject}"` : '';
        this.alertify.success(this.translateService.instant('text.email.EmailMessageRemoved', { subject }));
        if (action.message.id === selectedMessageId) {
          this.emailsRoutingService.closeSelectedEmail().pipe(take(1)).subscribe();
        }
        this.emailsNavigationStore.fetchCustomFolder$(action.message.parentFolderId);
        const actions: Action<string>[] = [
          FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - 1, limit: 1 }),
        ];

        if (action.message.isDraft) {
          actions.push(SetOpenedDraftMessage({ message: null }));
        }

        return actions;
      }),
    ),
  );

  readonly selectedMessagesRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveSelectedEmailMessagesSuccess),
      switchMap(({ selectedMessagesCount, selectedFolderId }) => {
        const pagination = this.messagesPagination();
        this.alertify.success(this.translateService.instant('text.email.EmailsMessagesRemoved', { count: selectedMessagesCount }));
        this.emailsNavigationStore.fetchCustomFolder$(selectedFolderId);
        return [
          FetchMessagesRangeStart({ offset: pagination.offset + pagination.limit - selectedMessagesCount, limit: selectedMessagesCount }),
          ResetMessagesSelection(),
        ];
      }),
    ),
  );

  readonly messagesMovedToFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MoveMessagesToFolderSuccess),
      map(({ targetFolderId, sourceFolderId }) => {
        this.emailsNavigationStore.fetchCustomFolder$(targetFolderId);
        this.emailsNavigationStore.fetchCustomFolder$(sourceFolderId);
        return ResetMessagesPagination({});
      }),
    ),
  );

  readonly messageForwarded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForwardOrReplyMessageSuccess),
      withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
      switchMap(([{ forwardedMessage, message, silent }, selectedMenuItem]) => {
        const actions: Action<string>[] = [];
        if (!silent) {
          actions.push(SetOpenedDraftMessage({ message: forwardedMessage }));
        }
        if (forwardedMessage.parentFolderId === selectedMenuItem && !message.id) {
          this.emailsNavigationStore.fetchCustomFolder$(forwardedMessage.parentFolderId);
          this.emailsMessagesStore.fetchMessageAttachments$(forwardedMessage.id);
          actions.push(
            ResetMessagesPagination({}),
          );
        }
        return actions;
      }),
    ),
  );

  fetchMessagesRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FetchMessagesRangeStart),
      withLatestFrom(
        this.store.pipe(select(emailsReducer.getSelectedMenuItemId)),
        this.store.pipe(select(emailsReducer.getMessagesPagination)),
        this.store.pipe(select(emailsReducer.getMessageTotalItemsCount)),
      ),
      filter(([{ offset }, selectedMenuItemId, , totalItemsCount]) =>
        !!selectedMenuItemId
        && (isNil(totalItemsCount) || offset <= totalItemsCount),
      ),
      switchMap(([
        action,
        selectedMenuItemId,
        pagination,
      ]) => {
        const { offset, limit } = action;
        const paginationRequest = { ...pagination, offset, limit };

        return this.emailsService.getMessagesByAlias(selectedMenuItemId, paginationRequest).pipe(
          switchMap(({ count, currentPage }) => {
            this.emailsMessagesStore.addMessages({ messages: currentPage, replace: false, offset, limit });
            const actions: Action<string>[] = [];
            if (isNumber(count)) {
              actions.push(SetTotalItemsCount({ count }));
            }
    
            return actions;
          }),
          catchError(() => {
            this.emailsMessagesStore.addMessages({ messages: [], replace: false });
            return of();
          }),
        );
      }),
    ),
  );

  emailsMessageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailsMessageChangeEvent),
      bufferTime(300),
      filter(events => !!events.length),
      switchMap(events => forkJoin([
        of(events),
        this.store.pipe(select(emailsReducer.getSelectedMenuItemId), take(1)),
      ])),
      switchMap(([actions, selectedMenuItemId]) => {
        const pagination = this.messagesPagination();
        const events = uniqWith<EmailMessageChangeEventI>(actions.map(action => action.event), isEqual);
        const uniqFoldersIds = uniq(events.map(event => event.parentFolderId).filter(Boolean));

        const actionsToDispatch: Action<string>[] = [
          GetUnreadCountStart(),
        ];
        const foldersReqestsLimit = 5;
        if (uniqFoldersIds.length > foldersReqestsLimit) {
          this.emailsNavigationStore.fetchCustomFolders$();
        } else {
          uniqFoldersIds.map(folderId => this.emailsNavigationStore.fetchCustomFolder$(folderId));
        }

        if (uniqFoldersIds.includes(selectedMenuItemId)) {
          const currentFolderEvents = events.filter(event => event.parentFolderId === selectedMenuItemId);
          currentFolderEvents.forEach(event => {
            switch(event.changeType) {
              case EmailMesssageChangeActionType.Deleted:
                this.emailsMessagesStore.removeMessage$(event.id);
                break;
              case EmailMesssageChangeActionType.Created:
                actionsToDispatch.push(
                  FetchMessagesRangeStart({
                    offset: pagination.offset,
                    limit: pagination.limit,
                  }),
                );
                break;
              case EmailMesssageChangeActionType.Updated:
                this.emailsMessagesStore.fetchMessage$(event.id);
                break;
            }
          });
        }

        return actionsToDispatch;
      }),
    ),
  );

  private readonly messagesPagination$ = this.store.pipe(select(emailsReducer.getMessagesPagination));
  private readonly messagesPagination = toSignal(this.messagesPagination$);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<emailsReducer.EmailsCommonState>,
    private readonly emailsService: IrisEmailsService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly emailsRoutingService: IrisEmailsRoutingService,
  ) {}
}
