import { inject, Injectable } from '@angular/core';
import { IrisUserSettingsService } from '@iris/common/services/user-settings.service';
import { IrisUserService } from '@iris/common/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { IrisEmailSettingsAlias, IrisEmailUserSettingsResponseI } from '../../models/IrisEmailSettings';
import * as emailsReducer from '../index';
import {
  SetSelectedEmail,
  EmailSelected,
  GetEmailsUserSettingsStart,
  GetEmailsUserSettingsSuccess,
  GetEmailsUserSettingsError,
  GetFolderByShortcutStart,
  GetFolderByShortcutSuccess,
  GetFolderByShortcutError,
  GetUnreadCountStart,
  GetUnreadCountSuccess,
  GetUnreadCountError,
  SaveMessageAsDraftStart,
  SetOpenedDraftMessage,
  ResetMessagesPagination,
  SaveMessageAsDraftError,
  SendOpenedDraftEmailStart,
  SendOpenedDraftEmailError,
} from './emails-global.actions';
import { IrisEmailsService } from '../../services/emails.service';
import { IrisEmailsMessagesStore } from '../messages/emails-messages.signal-store';
import { IrisEmailsNavigationStore } from '../navigation/emails-navigation.signal-store';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class IrisEmailsGlobalEffects {
  private readonly emailsMessagesStore = inject(IrisEmailsMessagesStore);
  private readonly emailsNavigationStore = inject(IrisEmailsNavigationStore);

  setSelectedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedEmail),
      map(action => {
        const message = this.emailsMessagesStore.getMessageById(action.emailId)();
        if (!!message && !message.markedAsRead) {
          this.emailsMessagesStore.markMessageAsRead$(action.emailId);
        }
        return EmailSelected(action);
      }),
    ),
  );

  GetEmailsUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEmailsUserSettingsStart),
      switchMap(() =>
        this.userSettingsService
          .getUserSettingsById(IrisEmailSettingsAlias.global, this.userId)
          .pipe(
            map((response: IrisEmailUserSettingsResponseI) => GetEmailsUserSettingsSuccess({ settings: response.settings })),
            catchError(() => of(GetEmailsUserSettingsError())),
          ),
      ),
    ),
  );

  getFolderByShortcut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetFolderByShortcutStart),
      mergeMap(({ folderShortcut }) => this.emailsService.getCustomFolder(folderShortcut).pipe(
        map(folder => GetFolderByShortcutSuccess({ folderId: folder.id, folderShortcut })),
        catchError(() => of(GetFolderByShortcutError())),
      )),
    ),
  );

  getUnreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUnreadCountStart),
      mergeMap(() => this.emailsService.getUnreadCount().pipe(
        map(unreadCount => GetUnreadCountSuccess({ unreadCount })),
        catchError(() => of(GetUnreadCountError())),
      )),
    ),
  );

  saveMessageAsDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveMessageAsDraftStart),
      switchMap(action => this.emailsService.saveMessageAsDraft(action.message).pipe(
        switchMap(messageResponse => this.emailsService.getMessageById(messageResponse.id, true)),
        withLatestFrom(this.store.pipe(select(emailsReducer.getSelectedMenuItemId))),
        switchMap(([message, selectedMenuItem]) => {
          const actions: Action<string>[] = [];
          if (!action.silent) {
            actions.push(SetOpenedDraftMessage({ message }));
          }
          if (message.parentFolderId === selectedMenuItem && !action.message.id) {
            this.emailsNavigationStore.fetchCustomFolder$(message.parentFolderId);
            actions.push(
              ResetMessagesPagination({}),
            );
          }
          return actions;
        }),
        catchError(() => of(SaveMessageAsDraftError())),
      )),
    ),
  );

  sendOpenedDraftEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SendOpenedDraftEmailStart),
      switchMap(action => this.emailsService.sendDraftMessage(action.message).pipe(
        tap(() => {
          this.alertify.success(this.translateService.instant('text.email.MessageSent'));
        }),
        tap(() => this.emailsNavigationStore.fetchCustomFolders$()),
        switchMap(() => [
          SetOpenedDraftMessage({ message: null }),
          ResetMessagesPagination({}),
        ]),
        catchError(() => of(SendOpenedDraftEmailError())),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<emailsReducer.EmailsCommonState>,
    private readonly userSettingsService: IrisUserSettingsService,
    private readonly userService: IrisUserService,
    private readonly emailsService: IrisEmailsService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
  ) {}

  get userId(): number {
    return this.userService?.me?.id;
  }
}
