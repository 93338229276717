import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as emailsGlobalReducer from './global/emails-global.reducer';

export interface EmailsCommonState {
  emailsGlobal: emailsGlobalReducer.EmailsGlobalState;
}

export const emailsCommonReducers: ActionReducerMap<EmailsCommonState> = {
  emailsGlobal: emailsGlobalReducer.reducer,
};

export const getDynamicState = createFeatureSelector<EmailsCommonState>('emailsCommonModule');

// global
export const getGlobalState = createSelector(
  getDynamicState,
  state => state.emailsGlobal,
);

export const getSelectedMenuItemId = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMenuItemId,
);

export const getSelectedMessageId = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMessageId,
);

export const getSelectedMessagesIds = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMessagesIds,
);

export const getDeselectedMessagesIds = createSelector(
  getGlobalState,
  emailsGlobalReducer.deselectedMessagesIds,
);

export const getMessagesPagination = createSelector(
  getGlobalState,
  emailsGlobalReducer.messagesPagination,
);

export const getMessageTotalItemsCount = createSelector(
  getGlobalState,
  emailsGlobalReducer.messageTotalItemsCount,
);

export const getCheckAll = createSelector(
  getGlobalState,
  emailsGlobalReducer.checkAll,
);

export const getSelectedMessagesCount = createSelector(
  getCheckAll,
  getMessageTotalItemsCount,
  getSelectedMessagesIds,
  getDeselectedMessagesIds,
  (checkAll, messagesCount, selectedMessagesIds, deselectedMessagesIds) => {
    return checkAll ? messagesCount - deselectedMessagesIds.length : selectedMessagesIds.length;
  },
);

export const getOpenedDraftMessage = createSelector(
  getGlobalState,
  emailsGlobalReducer.openedDraftMessage,
);

export const hasMessagesNextPage = createSelector(
  getGlobalState,
  emailsGlobalReducer.hasMessagesNextPage,
);

export const getUserSettings = createSelector(
  getGlobalState,
  emailsGlobalReducer.getUserSettings,
);

export const unreadCount = createSelector(
  getGlobalState,
  emailsGlobalReducer.unreadCount,
);
